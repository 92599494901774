import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { ChatBotContainer } from './components/chatbot/ChatBotContainer';

function App() {
  return (
    <div>
      <ChatBotContainer/>
    </div>
  );
}

export default App;
