import {
  Container,
  Row,
  Col,
  Stack,
  Image,
  Button
} from 'react-bootstrap';
import logo from "../../assets/logo.png";

const Header = () => {

  return (
    <Stack style={
      {
        width: "100%",
        height: "66px",
        alignItems: "center",
        alignContent: "center",
        justifyItems: "center",
        justifyContent: "center",
        padding: "10px",
        position: "fixed",
        backgroundColor: "#fff"
      }
    }>
      <Container>
        <Row >
          <Col>
            <Image src={logo}
              rounded
              width={"100px"}
              height={"50px"}/></Col>
          <Col>
            <Stack direction="horizontal"
              gap={0}>
              {
              Links.map((element) => (
                <>
                  <div className="p-2">
                    <Button href={
                        element.link
                      }
                      style={
                        {
                          fontSize: "12px",
                          backgroundColor: "transparent",
                          color: "#2C2C33",
                          border: "none"
                        }
                      }
                      onClick={
                        () => console.log(element.link)
                    }>
                      {
                      element.label
                    }</Button>
                  </div>
                  <div className="vr"
                    style={
                      {
                        height: "20px",
                        marginTop: "15px"
                      }
                    }/>
                </>
              ))
            }
              <div className="p-2 ms-auto">
                <Button href="https://ielts.mx/inscripciones-ielts/"
                  style={
                    {
                      backgroundColor: "#e21639",
                      color: "#ffffff",
                      border: "none",
                      fontSize: "12px",
                      padding: "5px"
                    }
                }>Registro Examen</Button>
              </div>
            </Stack>
          </Col>
        </Row>
      </Container>
    </Stack>
  );
}
export const Links = [
  {
    id: "1",
    label: "Inicio",
    link: "/"
  },
  {
    id: "2",
    label: "Preparate",
    link: "/"
  },
  {
    id: "3",
    label: "Certificate",
    link: "/"
  },
  {
    id: "3",
    label: "Paquete examen gratis",
    link: "https://ielts.mx/paquetes-ielts/"
  }, {
    id: "3",
    label: "Sedes",
    link: "https://ielts.mx/sedes-ielts-mexico/"
  },
]

export default Header;
