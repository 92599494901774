import {
  Container,
  Row,
  Col,
  Stack,
  Image,
  Button

} from 'react-bootstrap';
import logo from "../../assets/logo.png";

const Footer = () => {

  return (
    <Row item
      xs={12}
      sm={12}
      md={12}
      lg={12}>
      <Stack style={
        {
          backgroundColor: "#2C2C33",
          width: "100%",
          height: "auto",
          minHeight: "204px",
          padding: "10px"
        }
      }>
        <Container>
          <Row
          
          >
            <Col xs={12}
              md={4}>
              <Image src={logo}
                rounded
                width={"300px"}
                height={"150px"}/>
            </Col>
            <Col xs={12}
              md={4}>
              <Stack style={
                {alignItems: "flex-start"}
              }>
                {
                Links.map((element) => (
                  <Button variant="link"
                    href={
                      element.link
                    }
                    style={
                      {
                        color: "#fff",
                        fontSize: "12px"
                      }
                  }>
                    {
                    element.label
                  }</Button>
                ))
              } </Stack>

            </Col>
            <Col xs={12}
              md={4}>
              <Stack style={
                {alignItems: "flex-start"}
              }>
                {
                Links2.map((element) => (
                  <Button variant="link"
                    href={
                      element.link
                    }
                    style={
                      {
                        color: "#fff",
                        fontSize: "12px"
                      }
                  }>
                    {
                    element.label
                  }</Button>
                ))
              } </Stack>
            </Col>
          </Row>
        </Container>

      </Stack>
      <Stack direction='horizontal'
        style={
          {
            backgroundColor: "#212121",
            width: "100%",
            height: "auto",
            minHeight: "44px",
            maxHeight: "44px",
            padding: "20px"
          }
      }>
        <Container>
          <Row style={
              {
                alignItems: "center",
                alignContent: "center",
                justifyItems: "center",
                justifyContent: "center",
                alignSelf: "center"
              }
            }
            xs="auto">
            <Col style={
              {
                color: "#fff",
                fontSize: "8px"
              }
            }>2021 © Copyright - IELTS México</Col>
            <Col style={
              {
                color: "#fff",
                fontSize: "8px"
              }
            }>Aviso de Privacidad</Col>
            <Col style={
              {
                color: "#fff",
                fontSize: "8px"
              }
            }>Términos y Condiciones</Col>
            <Col style={
              {
                color: "#fff",
                fontSize: "8px"
              }
            }>Términos y condiciones generales para la contratación de Exámenes Internacionales</Col>

          </Row>

        </Container>
      </Stack>
    </Row>

  );
}

export const Links = [
  {
    id: "1",
    label: "¿Que es el IELTS?",
    link: "https://ielts.mx/que-es-ielts/"
  },
  {
    id: "2",
    label: "¿Como prepararse para el examen?",
    link: "https://ielts.mx/examen-ielts/"
  },
  {
    id: "3",
    label: "Noticias IELTS",
    link: "https://ielts.mx/noticias/"
  },
  {
    id: "4",
    label: "Solicita tu visa del reino unido",
    link: "https://ielts.mx/ukvi-ielts/"
  }, {
    id: "5",
    label: "Formacion de profesores de ingles",
    link: "https://universityih.com/cambridge-celta/"
  },
]

export const Links2 = [
  {
    id: "1",
    label: "Cursos de preparación",
    link: "https://ielts.mx/cursos-ielts/"
  },
  {
    id: "2",
    label: "Conoce studylelts",
    link: "https://ielts.mx/nuestros-profesores/"
  },
  {
    id: "3",
    label: "¿Donde realizar el examen IELTS",
    link: "https://ielts.mx/sedes-ielts-mexico/"
  },
  {
    id: "4",
    label: "Registro para el examen",
    link: "https://ielts.mx/inscripciones-ielts/"
  }, {
    id: "5",
    label: "Formacion de profesores de español",
    link: "https://universityih.com/dieele/"
  },

]


export default Footer;
